<template>
  <a-modal
    class="tag-form"
    :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <div class="form-item-outer">
        <a-form-model-item label="标签名称" prop="tagName">
          <a-input :disabled="handle == 'check'" v-model="rowData.tagName" placeholder="请输入标签名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="标签图标" prop="tagLogo">
          <QiniuUpload :disabled="handle == 'check'" type="image" :value.sync="rowData.tagLogo"></QiniuUpload>
          <div class="upload-hint-text">支持扩展名：.jpg .png</div>
        </a-form-model-item>
        <a-form-model-item label="标签说明" prop="tagDesc">
          <a-textarea :ditesabled="handle == 'check'" v-model="rowData.tagDesc" placeholder="请输入描述说明"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="flowStatus">
          <a-switch
            :disabled="handle == 'check'"
            v-model="rowData.flowStatus"
            checked-children="启用"
            un-checked-children="关闭"
          />
        </a-form-model-item>
        <a-form-model-item label="排序" prop="">
          <a-input :disabled="handle == 'check'" v-model="rowData.sort" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="新增商品">
          <a-button :disabled="handle == 'check'" type="primary" @click="onProductChooseModal()">新增商品</a-button>
        </a-form-model-item>
      </div>
      <div class="table-outer">
        <div v-if="dataList.length > 0">
          <a-table :columns="columns" :data-source="dataList" bordered>
            <span slot="action" slot-scope="item, row">
              <a :disabled="handle == 'check'" @click="ondelete(row.id)">删除</a>
            </span>
            <span slot="imgUrl" slot-scope="item">
              <ZoomMedia style="height: 20px;" :value="item"></ZoomMedia>
            </span>
          </a-table>
        </div>
      </div>
    </a-form-model>
    <ProductChoose ref="ProductChooseModal" @get-pruduct-list="getProductList"></ProductChoose>
    <!-- <SelectProductSkuModal ref="selectProductSkuModal" @select="toSelectGoods"></SelectProductSkuModal> -->
    <div class="footer-bts" v-if="handle != 'check'">
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="toSubmit">提交</a-button>
    </div>
  </a-modal>
</template>

<script>
import ProductChoose from './ProductChoose'
import { editProductTag, selectByIdProductTag, addProductTag } from '../api/ProductTagApi'

export default {
    components: {
        ProductChoose
    },
    data() {
        return {
            headers: {
                authorization: 'authorization-text',
            },
            dataList: [],
            visible: false,
            handle: 'add',
            rowData: {},
            loading: false,
            // 表单验证
            formRule: {
                tagDesc: [
                    { required: true, message: '请输入标签描述', trigger: 'blur' }
                ],
                tagName: [
                    { required: true, message: '请输入标签名', trigger: 'blur' }
                ],
                sort: [
                    { required: true, pattern:new RegExp(/^[0-9]+$/), message: '请输入排序(正整数)', trigger: 'blur' }
                ],
            },
            columns: [
                // {
                //     title: '商品名称',
                //     dataIndex: 'name',
                //     scopedSlots: { customRender: 'name' },
                // },
                // {
                //     title: '所属分类',
                //     dataIndex: 'categoryName',
                // },
                // {
                //     title: '销量',
                //     dataIndex: 'sellCount',
                // },
                // {
                //     title: '操作',
                //     dataIndex: 'action',
                //     scopedSlots: { customRender: 'action' },
                // }
                {
                  title: 'CNC商号',
                  dataIndex: 'cncCompany',
                  width: 120,
                  fixed: 'left',
                  ellipsis:true,
                },
                {
                  title: '商品名称',
                  dataIndex: 'name',
                  width: 200,
                  fixed: 'left',
                  ellipsis:true,
                  scopedSlots: { customRender: 'name'}
                },
                {
                  title: '规格',
                  dataIndex: 'specStr',
                  width: 150,
                  ellipsis:true
                },
                {
                  title: '总成号',
                  dataIndex: 'assemblyNumber',
                  ellipsis:true
                },
                {
                  title: '适用品牌',
                  dataIndex: 'carBrand',
                  key: 'carBrand',
                  align:'center',
                  ellipsis:true
                },
                {
                  title: '车型',
                  dataIndex: 'carModel',
                  key: 'carModel',
                  align:'center',
                  ellipsis:true,
                },
                {
                  title: '商品图标',
                  dataIndex: 'imgUrl',
                  ellipsis:true,
                  scopedSlots: { customRender: 'imgUrl' },
                },
            ],

        }
    },

    methods: {
      handleCancel() {
        this.rowData = {}
        this.visible = false
      },
  },

  methods: {
    handleCancel() {
      this.rowData = {}
      this.visible = false
    },
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.dataList = []
      if (handle == 'add') {
        this.$set(this.rowData, 'flowStatus', true)
      }
      if (handle == 'edit' || handle == 'check') {
        this.axios.get('/api/product/system/tag/selectById/' + row.id).then((res) => {
          this.rowData = res.body
          this.dataList = res.body.tagGoodsList
          // this.dataList.concat(res.body.tagGoodsList)
        })
      }
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.rowData.tagGoodFormList = this.dataList
        const res = this.handle === 'add' ? await addProductTag(this.rowData) : await editProductTag(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.rowData = {}
          this.visible = false
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
    // 上传
    handleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    onProductChooseModal() {
      const _this = this
      _this.$refs.ProductChooseModal.isShow()
    },
    getProductList(data) {
      const arr = new Map()
      let list = this.dataList.concat(data)
      this.dataList = list.filter((e) => !arr.has(e.id) && arr.set(e.id, 1))
    },
    // 删除商品
    ondelete(id) {
      this.dataList.splice(this.dataList.findIndex((e) => { return e.id == id }), 1)
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
.tag-form {
  .form-item-outer {
    padding: 0 50px;
  }
  .new-product {
    font-size: 20px;
    font-weight: bold;
  }
}
.product-choose {
  width: 73% !important;
}
</style>
