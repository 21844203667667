<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-01-07 17:37:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-26 16:56:56
-->
<template>
  <div class="tag-list">
    <a-modal class="tag-form" title="查看商品" style="top: 8px;" :width="1000" v-model="visible" :footer="null" :maskClosable="false">
      <a-table :columns="columns" :data-source="dataList"></a-table>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        dataList: [],
        columns: [
          // {
          //   title: '商品名称',
          //   dataIndex: 'name',
          //   key: 'name',
          //   scopedSlots: { customRender: 'name' },
          // },
          // {
          //   title: '所属分类',
          //   dataIndex: 'categoryName',
          //   key: 'categoryName',
          // },
          // {
          //   title: '品牌',
          //   dataIndex: 'brandName',
          //   key: 'brandName',
          // },
          // {
          //   title: '规格',
          //   dataIndex: 'specStr',
          //   key: 'specStr',
          // },
          // {
          //   title: '库存',
          //   key: 'realQuantity',
          //   dataIndex: 'realQuantity',
          //   scopedSlots: { customRender: 'realQuantity' }
          // },
          {
            title: 'CNC商号',
            dataIndex: 'cncCompany',
            width: 120,
            fixed: 'left',
            ellipsis:true,
          },
          {
            title: '商品名称',
            dataIndex: 'name',
            width: 200,
            fixed: 'left',
            ellipsis:true,
            scopedSlots: { customRender: 'name'}
          },
          {
            title: '规格',
            dataIndex: 'specStr',
            ellipsis:true
          },
          {
            title: '总成号',
            dataIndex: 'assemblyNumber',
            ellipsis:true
          },
          {
            title: '适用品牌',
            dataIndex: 'carBrand',
            key: 'carBrand',
            align:'center',
            ellipsis:true
          },
          {
            title: '车型',
            dataIndex: 'carModel',
            key: 'carModel',
            align:'center',
            ellipsis:true,
          },
          {
            title: '商品图标',
            dataIndex: 'imgUrl',
            ellipsis:true,
            scopedSlots: { customRender: 'imgUrl' },
          },
        ]
      }
    },
    methods: {
      isShow(item) {
        this.visible = true
        this.dataList = item.tagGoodsList
      },
      toSubmit() {
        this.visible = false
      }
    }
  }
</script>

<style lang="scss">
  
</style>
